import './NavBarMMS.css';
import React from "react";
import ReactDOM from "react-dom";
import LogoMMS from "./img/logoMMS.png";
function navBarMMS() {
    ReactDOM.render(
        <img className="img-fluid" src={LogoMMS} alt="MMS" />,
        document.getElementById('logoMMS')
    );
};

export default navBarMMS;
